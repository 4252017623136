//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import moment from 'moment'

export default {
    data() {
        return {
            searchBarAdded: false,
            sticky: false
        }
    },
    computed: {
        ...mapState(['customer', 'order', 'options', 'showNav', 'width', 'location', 'user', "navMenus"]),
        cartCount() {
            return this.order ? this.order.products.length : 0
        },
        mq() {
            return this.$mq
        },
        device() {
            return this.$mq
        },
        fixedMenu() {
            return this.navMenus["fixed-menu"] ? this.navMenus["fixed-menu"] : []
        },
        cartLink() {
            let link = this.options.orderPage
            if (this.order && this.order.products.length > 0) {
                if (this.customer.type != null) {
                    link = this.options.orderCheckoutPage
                } else if (this.options.guestCheckout) {
                    link = this.options.orderAccountPage
                } else {
                    link = this.options.signinPage
                }
            }
            return link;
        },
        menuLink() {
            let link = this.options.orderPage
            if (!this.location) {
                link = this.options.locationsPage
            }
            return link
        },
        mq() {
            return this.$mq
        },
    },
    mounted() {
        window.addEventListener('scroll', this.scroll)

        // window.addEventListener('resize', this.resize)
        // this.$nextTick(() => {
        //     this.resize()
        // })
    },
    destroyed() {
        // window.removeEventListener('resize', this.resize)
        window.addEventListener('scroll', this.scroll)
    },
    methods: {
        handleClick(i, url, sublinks) {
            // if (i === 3) {
            //   this.covidHidden = !this.covidHidden
            //   if (this.covidHidden === true) {
            //     document.getElementById('covid-link').classList.add('covid-link')
            //   } else {
            //     document.getElementById('covid-link').classList.remove('covid-link')
            //   }
            //   return
            // }

            // console.log('url::: ', url)
            // console.log('sublinks::: ', sublinks)

            if (!sublinks || this.mq == "lg" || this.mq == "xl") {
                if (this.externalURL(url)) {
                    location.href = url
                } else {
                    this.$router.push({
                        path: "/" + url,
                    });
                }
                this.activeKey = null;
            }
            if (this.mq == "sm" || this.mq == "md") {
                if (i == this.activeKey) {
                    this.activeKey = null;
                    if (this.externalURL(url)) {
                        location.href = url;
                    } else {
                        this.$router.push({
                            path: "/" + url,
                        });
                    }
                    this.$store.commit("setShowNav", false)
                } else {
                    this.activeKey = i
                }
            }

            if (url && (url == '/order/' || url == '/menu/')) {
                this.activeKey = i
            }

        },
        internalURL(srt) {
            return str.replace("menu/")
        },
        externalURL(str) {
            var pattern = new RegExp(
                "^(https?:\\/\\/)?" +
                "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
                "((\\d{1,3}\\.){3}\\d{1,3}))" +
                "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
                "(\\?[;&a-z\\d%_.~+=-]*)?" +
                "(\\#[-a-z\\d_]*)?$",
                "i"
            );
            return !!pattern.test(str)
        },
        scroll() {
            let top = 0
            try {
                // top = document.querySelector('.order-categories > nav:first-of-type').getBoundingClientRect().top
                // top = document.querySelector('.nav-container nav:first-of-type').getBoundingClientRect().top 
                top = window.scrollY

            } catch (err) {
                top = 0
            }
            if (top >= 68) {
                this.sticky = true
            } else {
                this.sticky = false
            }


            // console.log('this.sticky:::: ', this.sticky)
        },
        // resize() {
        //     if ((this.device == 'lg' || this.device == 'xl') && this.searchBarAdded == false) {
        //         ANSWERS.init({
        //             apiKey: '663ad26366c43fa2d76bb313d4509b11',
        //             experienceKey: 'krystal-answers-experience',
        //             businessId: '921690',
        //             experienceVersion: 'PRODUCTION',
        //             locale: 'en',
        //             onReady: function () {
        //                 this.addComponent('SearchBar', {
        //                     container: '.search-bar',
        //                     name: 'search-bar-' + moment().unix(),
        //                     redirectUrl: '/search',
        //                     placeholderText: 'Search…',
        //                     areDuplicateNamesAllowed: true
        //                 })
        //             }
        //         })
        //         this.searchBarAdded = true
        //     }
        // }
    },
}
