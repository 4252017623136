import Vue from 'vue'
import { wrapFunctional } from './utils'

const components = {
  AccountFavorites: () => import('../../components/account/AccountFavorites.vue' /* webpackChunkName: "components/account-favorites" */).then(c => wrapFunctional(c.default || c)),
  AccountGuest: () => import('../../components/account/AccountGuest.vue' /* webpackChunkName: "components/account-guest" */).then(c => wrapFunctional(c.default || c)),
  AccountInfo: () => import('../../components/account/AccountInfo.vue' /* webpackChunkName: "components/account-info" */).then(c => wrapFunctional(c.default || c)),
  AccountLocations: () => import('../../components/account/AccountLocations.vue' /* webpackChunkName: "components/account-locations" */).then(c => wrapFunctional(c.default || c)),
  AccountOffers: () => import('../../components/account/AccountOffers.vue' /* webpackChunkName: "components/account-offers" */).then(c => wrapFunctional(c.default || c)),
  AccountPayments: () => import('../../components/account/AccountPayments.vue' /* webpackChunkName: "components/account-payments" */).then(c => wrapFunctional(c.default || c)),
  AccountSettings: () => import('../../components/account/AccountSettings.vue' /* webpackChunkName: "components/account-settings" */).then(c => wrapFunctional(c.default || c)),
  AccountSignin: () => import('../../components/account/AccountSignin.vue' /* webpackChunkName: "components/account-signin" */).then(c => wrapFunctional(c.default || c)),
  AccountSignup: () => import('../../components/account/AccountSignup.vue' /* webpackChunkName: "components/account-signup" */).then(c => wrapFunctional(c.default || c)),
  AccountSignupOld: () => import('../../components/account/AccountSignupOld.vue' /* webpackChunkName: "components/account-signup-old" */).then(c => wrapFunctional(c.default || c)),
  OrderHistory: () => import('../../components/account/OrderHistory.vue' /* webpackChunkName: "components/order-history" */).then(c => wrapFunctional(c.default || c)),
  Accordion: () => import('../../components/common/Accordion.vue' /* webpackChunkName: "components/accordion" */).then(c => wrapFunctional(c.default || c)),
  AppLink: () => import('../../components/common/AppLink.vue' /* webpackChunkName: "components/app-link" */).then(c => wrapFunctional(c.default || c)),
  BackButton: () => import('../../components/common/BackButton.vue' /* webpackChunkName: "components/back-button" */).then(c => wrapFunctional(c.default || c)),
  CalloutSection: () => import('../../components/common/CalloutSection.vue' /* webpackChunkName: "components/callout-section" */).then(c => wrapFunctional(c.default || c)),
  Card: () => import('../../components/common/Card.vue' /* webpackChunkName: "components/card" */).then(c => wrapFunctional(c.default || c)),
  CardSection: () => import('../../components/common/CardSection.vue' /* webpackChunkName: "components/card-section" */).then(c => wrapFunctional(c.default || c)),
  CardSliderSection: () => import('../../components/common/CardSliderSection.vue' /* webpackChunkName: "components/card-slider-section" */).then(c => wrapFunctional(c.default || c)),
  Carousel: () => import('../../components/common/Carousel.vue' /* webpackChunkName: "components/carousel" */).then(c => wrapFunctional(c.default || c)),
  ContactForm: () => import('../../components/common/ContactForm.vue' /* webpackChunkName: "components/contact-form" */).then(c => wrapFunctional(c.default || c)),
  DaypartHeroSection: () => import('../../components/common/DaypartHeroSection.vue' /* webpackChunkName: "components/daypart-hero-section" */).then(c => wrapFunctional(c.default || c)),
  DaypartHeroSectionNew: () => import('../../components/common/DaypartHeroSectionNew.vue' /* webpackChunkName: "components/daypart-hero-section-new" */).then(c => wrapFunctional(c.default || c)),
  DaypartHeroSectionOld: () => import('../../components/common/DaypartHeroSectionOld.vue' /* webpackChunkName: "components/daypart-hero-section-old" */).then(c => wrapFunctional(c.default || c)),
  DaypartHeroSectionOldPreview: () => import('../../components/common/DaypartHeroSectionOldPreview.vue' /* webpackChunkName: "components/daypart-hero-section-old-preview" */).then(c => wrapFunctional(c.default || c)),
  DefaultButton: () => import('../../components/common/DefaultButton.vue' /* webpackChunkName: "components/default-button" */).then(c => wrapFunctional(c.default || c)),
  DownloadApp: () => import('../../components/common/DownloadApp.vue' /* webpackChunkName: "components/download-app" */).then(c => wrapFunctional(c.default || c)),
  DownloadAppV2: () => import('../../components/common/DownloadAppV2.vue' /* webpackChunkName: "components/download-app-v2" */).then(c => wrapFunctional(c.default || c)),
  ErrorPage: () => import('../../components/common/ErrorPage.vue' /* webpackChunkName: "components/error-page" */).then(c => wrapFunctional(c.default || c)),
  ExpandingSection: () => import('../../components/common/ExpandingSection.vue' /* webpackChunkName: "components/expanding-section" */).then(c => wrapFunctional(c.default || c)),
  Faqs: () => import('../../components/common/Faqs.vue' /* webpackChunkName: "components/faqs" */).then(c => wrapFunctional(c.default || c)),
  Hero: () => import('../../components/common/Hero.vue' /* webpackChunkName: "components/hero" */).then(c => wrapFunctional(c.default || c)),
  HeroSlide: () => import('../../components/common/HeroSlide.vue' /* webpackChunkName: "components/hero-slide" */).then(c => wrapFunctional(c.default || c)),
  HomeBoxes: () => import('../../components/common/HomeBoxes.vue' /* webpackChunkName: "components/home-boxes" */).then(c => wrapFunctional(c.default || c)),
  InstagramFeed: () => import('../../components/common/InstagramFeed.vue' /* webpackChunkName: "components/instagram-feed" */).then(c => wrapFunctional(c.default || c)),
  InstagramPost: () => import('../../components/common/InstagramPost.vue' /* webpackChunkName: "components/instagram-post" */).then(c => wrapFunctional(c.default || c)),
  KrystalHistory: () => import('../../components/common/KrystalHistory.vue' /* webpackChunkName: "components/krystal-history" */).then(c => wrapFunctional(c.default || c)),
  LatestNews: () => import('../../components/common/LatestNews.vue' /* webpackChunkName: "components/latest-news" */).then(c => wrapFunctional(c.default || c)),
  Loader: () => import('../../components/common/Loader.vue' /* webpackChunkName: "components/loader" */).then(c => wrapFunctional(c.default || c)),
  Lottie: () => import('../../components/common/Lottie.vue' /* webpackChunkName: "components/lottie" */).then(c => wrapFunctional(c.default || c)),
  Map: () => import('../../components/common/Map.vue' /* webpackChunkName: "components/map" */).then(c => wrapFunctional(c.default || c)),
  MapNew: () => import('../../components/common/MapNew.vue' /* webpackChunkName: "components/map-new" */).then(c => wrapFunctional(c.default || c)),
  Markdown: () => import('../../components/common/Markdown.vue' /* webpackChunkName: "components/markdown" */).then(c => wrapFunctional(c.default || c)),
  Media: () => import('../../components/common/Media.vue' /* webpackChunkName: "components/media" */).then(c => wrapFunctional(c.default || c)),
  Mission: () => import('../../components/common/Mission.vue' /* webpackChunkName: "components/mission" */).then(c => wrapFunctional(c.default || c)),
  NavLink: () => import('../../components/common/NavLink.vue' /* webpackChunkName: "components/nav-link" */).then(c => wrapFunctional(c.default || c)),
  Paragraph: () => import('../../components/common/Paragraph.vue' /* webpackChunkName: "components/paragraph" */).then(c => wrapFunctional(c.default || c)),
  ProductsSample: () => import('../../components/common/ProductsSample.vue' /* webpackChunkName: "components/products-sample" */).then(c => wrapFunctional(c.default || c)),
  ScrollNav: () => import('../../components/common/ScrollNav.vue' /* webpackChunkName: "components/scroll-nav" */).then(c => wrapFunctional(c.default || c)),
  SocialsModule: () => import('../../components/common/SocialsModule.vue' /* webpackChunkName: "components/socials-module" */).then(c => wrapFunctional(c.default || c)),
  StaticHero: () => import('../../components/common/StaticHero.vue' /* webpackChunkName: "components/static-hero" */).then(c => wrapFunctional(c.default || c)),
  StickyNav: () => import('../../components/common/StickyNav.vue' /* webpackChunkName: "components/sticky-nav" */).then(c => wrapFunctional(c.default || c)),
  StoryReelSection: () => import('../../components/common/StoryReelSection.vue' /* webpackChunkName: "components/story-reel-section" */).then(c => wrapFunctional(c.default || c)),
  Teaser: () => import('../../components/common/Teaser.vue' /* webpackChunkName: "components/teaser" */).then(c => wrapFunctional(c.default || c)),
  TwoChainz: () => import('../../components/common/TwoChainz.vue' /* webpackChunkName: "components/two-chainz" */).then(c => wrapFunctional(c.default || c)),
  YextSearch: () => import('../../components/common/YextSearch.vue' /* webpackChunkName: "components/yext-search" */).then(c => wrapFunctional(c.default || c)),
  Nutrition: () => import('../../components/common/nutrition.vue' /* webpackChunkName: "components/nutrition" */).then(c => wrapFunctional(c.default || c)),
  ConditionalCheckbox: () => import('../../components/forms/ConditionalCheckbox.vue' /* webpackChunkName: "components/conditional-checkbox" */).then(c => wrapFunctional(c.default || c)),
  ConditionalSelect: () => import('../../components/forms/ConditionalSelect.vue' /* webpackChunkName: "components/conditional-select" */).then(c => wrapFunctional(c.default || c)),
  DynamicForm: () => import('../../components/forms/DynamicForm.vue' /* webpackChunkName: "components/dynamic-form" */).then(c => wrapFunctional(c.default || c)),
  FeedbackResolution: () => import('../../components/forms/FeedbackResolution.vue' /* webpackChunkName: "components/feedback-resolution" */).then(c => wrapFunctional(c.default || c)),
  LocatorField: () => import('../../components/forms/LocatorField.vue' /* webpackChunkName: "components/locator-field" */).then(c => wrapFunctional(c.default || c)),
  Arrow: () => import('../../components/icons/Arrow.vue' /* webpackChunkName: "components/arrow" */).then(c => wrapFunctional(c.default || c)),
  IconArrowDownRed: () => import('../../components/icons/IconArrowDownRed.vue' /* webpackChunkName: "components/icon-arrow-down-red" */).then(c => wrapFunctional(c.default || c)),
  IconArrowRight: () => import('../../components/icons/IconArrowRight.vue' /* webpackChunkName: "components/icon-arrow-right" */).then(c => wrapFunctional(c.default || c)),
  IconCart: () => import('../../components/icons/IconCart.vue' /* webpackChunkName: "components/icon-cart" */).then(c => wrapFunctional(c.default || c)),
  IconClock: () => import('../../components/icons/IconClock.vue' /* webpackChunkName: "components/icon-clock" */).then(c => wrapFunctional(c.default || c)),
  IconCloseWhite: () => import('../../components/icons/IconCloseWhite.vue' /* webpackChunkName: "components/icon-close-white" */).then(c => wrapFunctional(c.default || c)),
  IconContact: () => import('../../components/icons/IconContact.vue' /* webpackChunkName: "components/icon-contact" */).then(c => wrapFunctional(c.default || c)),
  IconDelivery: () => import('../../components/icons/IconDelivery.vue' /* webpackChunkName: "components/icon-delivery" */).then(c => wrapFunctional(c.default || c)),
  IconFacebook: () => import('../../components/icons/IconFacebook.vue' /* webpackChunkName: "components/icon-facebook" */).then(c => wrapFunctional(c.default || c)),
  IconInstagram: () => import('../../components/icons/IconInstagram.vue' /* webpackChunkName: "components/icon-instagram" */).then(c => wrapFunctional(c.default || c)),
  IconInstagramLg: () => import('../../components/icons/IconInstagramLg.vue' /* webpackChunkName: "components/icon-instagram-lg" */).then(c => wrapFunctional(c.default || c)),
  IconOrder: () => import('../../components/icons/IconOrder.vue' /* webpackChunkName: "components/icon-order" */).then(c => wrapFunctional(c.default || c)),
  IconPhone: () => import('../../components/icons/IconPhone.vue' /* webpackChunkName: "components/icon-phone" */).then(c => wrapFunctional(c.default || c)),
  IconPickup: () => import('../../components/icons/IconPickup.vue' /* webpackChunkName: "components/icon-pickup" */).then(c => wrapFunctional(c.default || c)),
  IconPin: () => import('../../components/icons/IconPin.vue' /* webpackChunkName: "components/icon-pin" */).then(c => wrapFunctional(c.default || c)),
  IconStore: () => import('../../components/icons/IconStore.vue' /* webpackChunkName: "components/icon-store" */).then(c => wrapFunctional(c.default || c)),
  IconTiktok: () => import('../../components/icons/IconTiktok.vue' /* webpackChunkName: "components/icon-tiktok" */).then(c => wrapFunctional(c.default || c)),
  IconTwitter: () => import('../../components/icons/IconTwitter.vue' /* webpackChunkName: "components/icon-twitter" */).then(c => wrapFunctional(c.default || c)),
  IconYoutube: () => import('../../components/icons/IconYoutube.vue' /* webpackChunkName: "components/icon-youtube" */).then(c => wrapFunctional(c.default || c)),
  Banner: () => import('../../components/layout/Banner.vue' /* webpackChunkName: "components/banner" */).then(c => wrapFunctional(c.default || c)),
  BottomBanner: () => import('../../components/layout/BottomBanner.vue' /* webpackChunkName: "components/bottom-banner" */).then(c => wrapFunctional(c.default || c)),
  CartIcon: () => import('../../components/layout/CartIcon.vue' /* webpackChunkName: "components/cart-icon" */).then(c => wrapFunctional(c.default || c)),
  Column: () => import('../../components/layout/Column.vue' /* webpackChunkName: "components/column" */).then(c => wrapFunctional(c.default || c)),
  ErrorOverlay: () => import('../../components/layout/ErrorOverlay.vue' /* webpackChunkName: "components/error-overlay" */).then(c => wrapFunctional(c.default || c)),
  Footer: () => import('../../components/layout/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c)),
  FooterLogo: () => import('../../components/layout/FooterLogo.vue' /* webpackChunkName: "components/footer-logo" */).then(c => wrapFunctional(c.default || c)),
  Hamburger: () => import('../../components/layout/Hamburger.vue' /* webpackChunkName: "components/hamburger" */).then(c => wrapFunctional(c.default || c)),
  Header: () => import('../../components/layout/Header.vue' /* webpackChunkName: "components/header" */).then(c => wrapFunctional(c.default || c)),
  Logo: () => import('../../components/layout/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c)),
  MenuPromo: () => import('../../components/layout/MenuPromo.vue' /* webpackChunkName: "components/menu-promo" */).then(c => wrapFunctional(c.default || c)),
  NavMenu: () => import('../../components/layout/NavMenu.vue' /* webpackChunkName: "components/nav-menu" */).then(c => wrapFunctional(c.default || c)),
  Page: () => import('../../components/layout/Page.vue' /* webpackChunkName: "components/page" */).then(c => wrapFunctional(c.default || c)),
  UserIcon: () => import('../../components/layout/UserIcon.vue' /* webpackChunkName: "components/user-icon" */).then(c => wrapFunctional(c.default || c)),
  LocationItem: () => import('../../components/locations/LocationItem.vue' /* webpackChunkName: "components/location-item" */).then(c => wrapFunctional(c.default || c)),
  LocationPage: () => import('../../components/locations/LocationPage.vue' /* webpackChunkName: "components/location-page" */).then(c => wrapFunctional(c.default || c)),
  LocationSuggestions: () => import('../../components/locations/LocationSuggestions.vue' /* webpackChunkName: "components/location-suggestions" */).then(c => wrapFunctional(c.default || c)),
  LocationsGrid: () => import('../../components/locations/LocationsGrid.vue' /* webpackChunkName: "components/locations-grid" */).then(c => wrapFunctional(c.default || c)),
  LocationsList: () => import('../../components/locations/LocationsList.vue' /* webpackChunkName: "components/locations-list" */).then(c => wrapFunctional(c.default || c)),
  LocationsMap: () => import('../../components/locations/LocationsMap.vue' /* webpackChunkName: "components/locations-map" */).then(c => wrapFunctional(c.default || c)),
  LocationsPhoto: () => import('../../components/locations/LocationsPhoto.vue' /* webpackChunkName: "components/locations-photo" */).then(c => wrapFunctional(c.default || c)),
  LocationsResults: () => import('../../components/locations/LocationsResults.vue' /* webpackChunkName: "components/locations-results" */).then(c => wrapFunctional(c.default || c)),
  LocationsSearch: () => import('../../components/locations/LocationsSearch.vue' /* webpackChunkName: "components/locations-search" */).then(c => wrapFunctional(c.default || c)),
  LocationsSearchColumn: () => import('../../components/locations/LocationsSearchColumn.vue' /* webpackChunkName: "components/locations-search-column" */).then(c => wrapFunctional(c.default || c)),
  LocationsSearchMinimal: () => import('../../components/locations/LocationsSearchMinimal.vue' /* webpackChunkName: "components/locations-search-minimal" */).then(c => wrapFunctional(c.default || c)),
  LocationsViewAll: () => import('../../components/locations/LocationsViewAll.vue' /* webpackChunkName: "components/locations-view-all" */).then(c => wrapFunctional(c.default || c)),
  Coupons: () => import('../../components/order/Coupons.vue' /* webpackChunkName: "components/coupons" */).then(c => wrapFunctional(c.default || c)),
  CustomerDetails: () => import('../../components/order/CustomerDetails.vue' /* webpackChunkName: "components/customer-details" */).then(c => wrapFunctional(c.default || c)),
  DateTimePicker: () => import('../../components/order/DateTimePicker.vue' /* webpackChunkName: "components/date-time-picker" */).then(c => wrapFunctional(c.default || c)),
  DeliveryDetails: () => import('../../components/order/DeliveryDetails.vue' /* webpackChunkName: "components/delivery-details" */).then(c => wrapFunctional(c.default || c)),
  FiservPayment: () => import('../../components/order/FiservPayment.vue' /* webpackChunkName: "components/fiserv-payment" */).then(c => wrapFunctional(c.default || c)),
  OloPayment: () => import('../../components/order/OloPayment.vue' /* webpackChunkName: "components/olo-payment" */).then(c => wrapFunctional(c.default || c)),
  OrderAgain: () => import('../../components/order/OrderAgain.vue' /* webpackChunkName: "components/order-again" */).then(c => wrapFunctional(c.default || c)),
  OrderCategories: () => import('../../components/order/OrderCategories.vue' /* webpackChunkName: "components/order-categories" */).then(c => wrapFunctional(c.default || c)),
  OrderConfirmation: () => import('../../components/order/OrderConfirmation.vue' /* webpackChunkName: "components/order-confirmation" */).then(c => wrapFunctional(c.default || c)),
  OrderInformation: () => import('../../components/order/OrderInformation.vue' /* webpackChunkName: "components/order-information" */).then(c => wrapFunctional(c.default || c)),
  OrderItems: () => import('../../components/order/OrderItems.vue' /* webpackChunkName: "components/order-items" */).then(c => wrapFunctional(c.default || c)),
  OrderSummary: () => import('../../components/order/OrderSummary.vue' /* webpackChunkName: "components/order-summary" */).then(c => wrapFunctional(c.default || c)),
  OrderTotals: () => import('../../components/order/OrderTotals.vue' /* webpackChunkName: "components/order-totals" */).then(c => wrapFunctional(c.default || c)),
  PaymentConfirm: () => import('../../components/order/PaymentConfirm.vue' /* webpackChunkName: "components/payment-confirm" */).then(c => wrapFunctional(c.default || c)),
  PaymentDetails: () => import('../../components/order/PaymentDetails.vue' /* webpackChunkName: "components/payment-details" */).then(c => wrapFunctional(c.default || c)),
  PaymentFrame: () => import('../../components/order/PaymentFrame.vue' /* webpackChunkName: "components/payment-frame" */).then(c => wrapFunctional(c.default || c)),
  PickupMode: () => import('../../components/order/PickupMode.vue' /* webpackChunkName: "components/pickup-mode" */).then(c => wrapFunctional(c.default || c)),
  SavedPayments: () => import('../../components/order/SavedPayments.vue' /* webpackChunkName: "components/saved-payments" */).then(c => wrapFunctional(c.default || c)),
  SubmitOrder: () => import('../../components/order/SubmitOrder.vue' /* webpackChunkName: "components/submit-order" */).then(c => wrapFunctional(c.default || c)),
  TimeDetails: () => import('../../components/order/TimeDetails.vue' /* webpackChunkName: "components/time-details" */).then(c => wrapFunctional(c.default || c)),
  Tips: () => import('../../components/order/Tips.vue' /* webpackChunkName: "components/tips" */).then(c => wrapFunctional(c.default || c)),
  VehicleDetails: () => import('../../components/order/VehicleDetails.vue' /* webpackChunkName: "components/vehicle-details" */).then(c => wrapFunctional(c.default || c)),
  MenuCategories: () => import('../../components/products/MenuCategories.vue' /* webpackChunkName: "components/menu-categories" */).then(c => wrapFunctional(c.default || c)),
  MenuCategory: () => import('../../components/products/MenuCategory.vue' /* webpackChunkName: "components/menu-category" */).then(c => wrapFunctional(c.default || c)),
  MenuFull: () => import('../../components/products/MenuFull.vue' /* webpackChunkName: "components/menu-full" */).then(c => wrapFunctional(c.default || c)),
  ProductConfiguration: () => import('../../components/products/ProductConfiguration.vue' /* webpackChunkName: "components/product-configuration" */).then(c => wrapFunctional(c.default || c)),
  ProductDetails: () => import('../../components/products/ProductDetails.vue' /* webpackChunkName: "components/product-details" */).then(c => wrapFunctional(c.default || c)),
  ProductImage: () => import('../../components/products/ProductImage.vue' /* webpackChunkName: "components/product-image" */).then(c => wrapFunctional(c.default || c)),
  ProductModifierList: () => import('../../components/products/ProductModifierList.vue' /* webpackChunkName: "components/product-modifier-list" */).then(c => wrapFunctional(c.default || c)),
  ProductModifiers: () => import('../../components/products/ProductModifiers.vue' /* webpackChunkName: "components/product-modifiers" */).then(c => wrapFunctional(c.default || c))
}

for (const name in components) {
  Vue.component(name, components[name])
  Vue.component('Lazy' + name, components[name])
}
