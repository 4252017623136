export default (context) => {

    // if (context.route && context.route.path.toString().includes('/menu')) {
    //     console.log('we are on menu')
    //     // redirect('https://krystal.olo.com/?stay=y')
    // }


    if (context.route && context.route.path.toString().includes('/order')) {
        if (context.route.path.toString().includes('/product')) { //redirect to product
            if (context.store.state.location) {
                //there is a location
                console.log('store.state.location.slug::: ', context.store.state.location.slug)
                if (context.store.state.location.slug) {
                    //there is a slug
                    return context.redirect(`${context.store.state.location.slug}products/${context.route.query?.id}/?stay=y`)
                    // redirect(`${store.state.location.slug}products/74714861/?stay=y`)
                }
            }
        } else if (context.route.path.toString().includes('/checkout')) { //redirect to home
            console.log('we are on checkout')
            if (context.req) {
                return context.redirect(context.req.headers.host)
            } else {
                // return context.redirect('https://krystal.preview.3owl.agency/')
                if (process.env.HOST_URL) {
                    return context.redirect(process.env.HOST_URL)
                }
            }
        } else {
            console.log('we are on order  https://order.krystal.com')
            console.log('store:::xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx ', context.store.state)
            if (context.store.state.location) {
                console.log('there is a location')
                // there is a location
                if (context.store.state.location.slug) {
                    //there is a slug
                    return context.redirect(`${context.store.state.location.slug}?stay=y`)
                    // redirect(`${context.store.state.location.slug}?stay=y`)
                }
            } else {
                console.log('no location')
                if (context.req) {
                    return context.redirect(context.req.headers.host)
                } else {
                    // return context.redirect('https://krystal.preview.3owl.agency/')
                    if (process.env.HOST_URL) {
                        return context.redirect(process.env.HOST_URL)
                    }
                }
            }
        }
    }


    if (context.route && context.route.path.toString().includes('/account')) {
        console.log('we are in an account route')
        console.log('context.req::: ', context.req)
        console.log('process.browser::::: ', process.browser)
        // return context.redirect('https://krystal.preview.3owl.agency/') 
        console.log('process.env.HOST_URL:::: ', process.env.HOST_URL)
        if (process.env.HOST_URL) {
            return context.redirect(process.env.HOST_URL)
        }
        // if (context.req) {
        //     return context.redirect(context.req.headers.host)
        // } else {
        //     return context.redirect('https://krystal.preview.3owl.agency/')
        // }
        // if (process.browser.window) {
        //     process.browser.window.onNuxtReady(() => { process.browser.window.$nuxt.$router.push('/') })
        // } else {
        //     return context.redirect(302, '/')
        // }
        
        // return context.redirect(302, '/')
        // context.store.commit('setRedirect', '/')
    }
    // if (context.route && context.route.path.toString().includes('/checkout')) {
    //     console.log('we are in an checkout route')
    //     if (process.browser.window) {
    //         process.browser.window.onNuxtReady(() => { process.browser.window.$nuxt.$router.push('/') })
    //     } else {
    //         return context.redirect(302, '/')
    //     }
    //     // return context.redirect(302, '/')
    // }

}

