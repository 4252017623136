//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from "vuex"
import moment from "moment"

export default {
    data() {
        return {
            // el: '#covid',
            covidHidden: true,
            activeKey: null,
            menuShow: false,
            timeout: null,
            menuIcons: {
                31523: 'https://a.storyblok.com/f/120533/264x264/a49a9b8ecf/breakfast.png',
                31526: 'https://a.storyblok.com/f/120533/78x78/33abc3917b/combos.png',
                31531: 'https://a.storyblok.com/f/120533/280x280/d9936599b2/drinks-and-desserts.png',
                32546: 'https://a.storyblok.com/f/120533/172x172/63f5d90bf4/loaded-fries.png',
                32554: 'https://a.storyblok.com/f/120533/296x314/a4b7599eee/pick-5.png',
                31527: 'https://a.storyblok.com/f/120533/172x172/ec947adf49/pups.png',
                32555: 'https://a.storyblok.com/f/120533/172x172/32f4c9a1df/sackful.png',
                // 31529: 'https://a.storyblok.com/f/120533/264x264/8e1b031358/snacks.png',
                31529: 'https://a.storyblok.com/f/120533/294x291/abb4c798fa/tots.png',
                32560: 'https://a.storyblok.com/f/120533/240x240/c260d31367/breakfast-combos.png',
                31524: 'https://a.storyblok.com/f/120533/305x305/17c67a5e2d/krystals-n-chicks.png',
                49421: 'https://a.storyblok.com/f/120533/200x165/6a0522f2b9/24packwithkrstals-fries2.png',
                56083: 'https://a.storyblok.com/f/120533/200x200/afa746e40a/drinks.png',
            }
        }
    },
    mounted() {
        // if (this.mq == 'sm' || this.mq == 'md') {
        //     ANSWERS.init({
        //         apiKey: '663ad26366c43fa2d76bb313d4509b11',
        //         experienceKey: 'krystal-answers-experience',
        //         businessId: '921690',
        //         experienceVersion: 'PRODUCTION',
        //         locale: 'en',
        //         onReady: function () {
        //             this.addComponent('SearchBar', {
        //                 container: '.search-bar-mobile',
        //                 name: 'search-bar-mobile-' + moment().unix(),
        //                 redirectUrl: '/search',
        //                 placeholderText: 'Search…',
        //             })
        //         }
        //     })
        // }

        // document.addEventListener('click', this.handleClickOutside);
    },
    beforeUnmount() {
        // document.removeEventListener('click', this.handleClickOutside);
    },
    computed: {
        ...mapState([
            "navMenus",
            "promo",
            "defaultMenu",
            "menu",
            "location",
            "order",
            "customer",
            "options",
        ]),
        mq() {
            return this.$mq
        },
        links() {
            return this.navMenus["main-menu"] ? this.navMenus["main-menu"] : []
        },
        socialMenu() {
            return this.navMenus["social-menu"] ? this.navMenus["social-menu"] : []
        },
        bottomMenu() {
            return this.navMenus["bottom-menu"]
        },
        footerMenu() {
            return this.navMenus['footer-menu'].slice(2)
        },
        flyoutMenu() {
            return this.navMenus['flyout-menu']
        },
        displayMenu() {
            return this.defaultMenu
        },
        cartLink() {
            let link = this.options.orderPage
            if (this.order && this.order.products.length > 0) {
                if (this.customer.type != null) {
                    link = this.options.orderCheckoutPage
                } else if (this.options.guestCheckout) {
                    link = this.options.orderAccountPage
                } else {
                    link = this.options.signinPage
                }
            }
            return link;
        },
    },
    methods: {
        handleClickOutside(event) {
            if (this.menuShow) {
                if (this.$refs.elementToDetectOutsideClick && !this.$refs.elementToDetectOutsideClick.contains(event.target)) {
                    this.menuShow = false
                }
            }
            
        },
        handleClickflyout() {
            this.menuShow = false
        },
        showFlighOutMenu() {
            // this.menuShow = !this.menuShow
        },
        slug(str) {
            str = str.toString().replace(/^\s+|\s+$/g, "");
            str = str.toLowerCase();
            let from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
            let to = "aaaaeeeeiiiioooouuuunc------";
            for (let i = 0, l = from.length; i < l; i++) {
                str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
            }
            str = str
                .replace(/[^a-z0-9 -]/g, "")
                .replace(/\s+/g, "-")
                .replace(/-+/g, "-");

            return str
        },
        itemTo(c) {
            let to = "#" + this.slug(c);
            if (this.menu) {
                to = "/order/" + to
            } else {
                to = "/menu/" + to
            }
            return to
        },
        handleMouseover(i) {
            // if (i === 3) {
            //   this.covidHidden = true
            // }
            setTimeout(
                function () {
                    if (this.mq == "lg" || this.mq == "xl") {
                        this.activeKey = i;
                        clearTimeout(this.timeout)
                    }
                }.bind(this),
                200
            );
        },
        handleMouseleave(i) {
            // if (i === 3) {
            //   this.covidHidden = false
            // }
            clearTimeout(this.timeout)
            if (this.mq == "lg" || this.mq == "xl") {
                this.timeout = setTimeout(
                    function () {
                        this.activeKey = null
                    }.bind(this),
                    200
                );
            }
        },
        handleClick(i, url, sublinks) {
            // if (i === 3) {
            //   this.covidHidden = !this.covidHidden
            //   if (this.covidHidden === true) {
            //     document.getElementById('covid-link').classList.add('covid-link')
            //   } else {
            //     document.getElementById('covid-link').classList.remove('covid-link')
            //   }
            //   return
            // }

            // console.log('url::: ', url)
            // console.log('sublinks::: ', sublinks)

            // if (!sublinks || this.mq == "lg" || this.mq == "xl") {
            //     if (this.externalURL(url)) {
            //         location.href = url
            //     } else {
            //         this.$router.push({
            //             path: "/" + url,
            //         });
            //     }
            //     this.activeKey = null;
            // }
            // if (this.mq == "sm" || this.mq == "md") {
            //     if (i == this.activeKey) {
            //         this.activeKey = null;
            //         if (this.externalURL(url)) {
            //             location.href = url;
            //         } else {
            //             this.$router.push({
            //                 path: "/" + url,
            //             });
            //         }
            //         this.$store.commit("setShowNav", false)
            //     } else {
            //         this.activeKey = i
            //     }
            // }

            // if (url && (url == '/order/' || url == '/menu/')) {
            //     this.activeKey = i
            // }

            this.activeKey = null;
            if (this.externalURL(url)) {
                location.href = url;
            } else {
                this.$router.push({
                    path: "/" + url,
                });
            }
            if (this.mq == "sm" || this.mq == "md") {
                this.$store.commit("setShowNav", false)
            }
            
        },
        internalURL(srt) {
            return str.replace("menu/")
        },
        externalURL(str) {
            var pattern = new RegExp(
                "^(https?:\\/\\/)?" +
                "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
                "((\\d{1,3}\\.){3}\\d{1,3}))" +
                "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
                "(\\?[;&a-z\\d%_.~+=-]*)?" +
                "(\\#[-a-z\\d_]*)?$",
                "i"
            );
            return !!pattern.test(str)
        }
    },
    watch: {
        "$route.path": function () {
            this.activeKey = null
        },
    },
};
